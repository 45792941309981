export enum StatusLogisticSession {
  OPTION = 'option',
  DATE_VALIDATED = 'validated',
  DATE_OK = 'ok',
  DATE_RETURN_CLIENT = 'return-client',
}

export interface ItemStatusLogisticSession {
  text: string,
  value: string,
  class: string,
}
