





















































import { defineComponent } from '@vue/composition-api'
import { formatISO9075, parseJSON } from 'date-fns'

export default defineComponent({
  name: 'DateFieldFilter',
  components: {
    DateField: () => import('@/components/base/DateField.vue'),
  },
  data () {
    return {
      filterValue: null as string | Array<string> | null,
    }
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Array],
      default: null,
    },
    range: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    dateToShow: {
      type: String,
      default: '',
    },
  },
  created () {
    this.filterValue = this.value as string | Array<string>
  },
  watch: {
    value (newValue: string | Array<string>) {
      this.filterValue = newValue
    },
  },
  methods: {
    filter () {
      if (!this.range) {
        this.$emit('day-with-no-hours', formatISO9075(parseJSON(this.filterValue as string)))
      }
    },
    clear () {
      if (this.range) {
        this.filterValue = []
        this.$emit('clear')
        return
      }
      this.filterValue = ''
      this.$emit('clear')
      this.$emit('day-with-no-hours', '')
    },
  },
})
