
































































import { defineComponent } from '@vue/composition-api'
import { format, formatISO, parse, parseISO, parseJSON } from 'date-fns'
import { useDate } from '@/composables/date'

export default defineComponent({
  name: 'DateField',

  props: {
    value: {
      type: [String, Array],
      default: () => null,
    },
    label: {
      type: String,
      default: 'Date',
    },
    locale: {
      type: String,
      default: 'fr',
    },
    textFieldClass: {
      type: String,
      default: '',
    },
    keepHour: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: 'required',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: String,
      default: '',
    },
    light: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    dateToShow: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      modalPicker: false,
    }
  },
  computed: {
    dateFormated: {
      get (): string | null | Array<string> {
        if (this.range) {
          if (!this.value) {
            return null
          }

          if (this.value.length > 1) {
            return useDate().getDateFormat(this.value[0] as string, 'dd/MM/yyyy') + ' - ' + useDate().getDateFormat(this.value[1] as string, 'dd/MM/yyyy')
          }

          return useDate().getDateFormat(this.value[0] as string, 'dd/MM/yyyy') as string
        }
        return this.value ? format(parseJSON(this.value as string), 'dd/MM/yyyy') : null
      },
      set (value: string) {
        if (value) {
          if (this.range) {
            return
          }
          this.$emit(
            'input',
            formatISO(parse(value, 'dd/MM/yyyy', parseJSON(this.value as string))),
          )
        } else {
          this.$emit('input', null)
        }
      },
    },

    dateIso: {
      get (): string | Array<string> | null {
        if (this.range) {
          return this.value as Array<string>
        }

        return this.value
          ? formatISO(parseJSON(this.value as string), { representation: 'date' })
          : null
      },
      set (value: string | Array<string>) {
        if (value) {
          if (this.range) {
            if (value.length > 1) {
              const date1 = parseISO(value[0])
              const date2 = parseISO(value[1])
              if (date1 > date2) {
                value = [value[1], value[0]]
              }
            }
            this.$emit('input', value)
            return
          }

          let dateTimeValue = value as string
          if (this.keepHour && this.value) {
            dateTimeValue = dateTimeValue + dateTimeValue.slice(10)
          }
          this.$emit('input', formatISO(parseISO(dateTimeValue)))
        }
      },
    },
  },
})
