
/* eslint-disable @typescript-eslint/no-explicit-any */
// This is the "store accessor":
// It initializes all the modules using a Vuex plugin (see store/index.ts)
// In here you import all your modules, call getModule on them to turn them
// into the actual stores, and then re-export them.

import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/modules/user'
import AreaModule from '@/store/modules/area'
import StatusModule from '@/store/modules/status'
import TrainingTypeModule from '@/store/modules/trainingType'
import ProfessionModule from '@/store/modules/profession'
import ThemeModule from '@/store/modules/theme'
import NavigationModule from '@/store/modules/navigation'
import ApiLogModule from '@/store/modules/apiLog'
import CarDealerSellerListModule from '@/components/molecules/select-search/store/carDealerSeller'
import CarDealerListModule from '@/views/dashboard/carDealer/store/carDealerList'
import CarDealerFormModule from '@/views/dashboard/carDealer/store/carDealerForm'
import CarTypeModule from '@/components/molecules/select-search/store/carType'
import CarBrandModule from '@/components/molecules/select-search/store/carBrand'
import CarModeleListModule from '@/components/molecules/select-search/store/carModele'
import CarModelFormModule from '@/views/dashboard/carModel/store/carModelForm'
import CarModelListModule from '@/views/dashboard/carModel/store/carModelList'
import CarEngineModule from '@/components/molecules/select-search/store/carEngine'
import CarFinishModule from '@/components/molecules/select-search/store/carFinish'
import CarColorModule from '@/components/molecules/select-search/store/carColor'
import RenterModule from '@/components/molecules/select-search/store/renter'
import CompanyContactListModule from '@/components/molecules/select-search/store/companyContact'
import TraineeListModule from '@/views/dashboard/trainee/store/traineeList'
import FilterListModule from '@/views/dashboard/trainee/store/filterList'
import TraineeFormModule from '@/views/dashboard/trainee/store/traineeForm'
import NeedTrainingFormModule from '@/views/dashboard/trainee/store/needTrainingForm'
import ImportModule from '@/views/dashboard/import/store/import'
import CompanyModule from '@/views/dashboard/company/store/company'
import CompanyFormModule from '@/views/dashboard/company/store/companyForm'
import ApiCompanyModule from '@/views/dashboard/company/store/apiCompany'
import ReminderFormModule from '@/views/dashboard/trainee/store/reminderForm'
import TrainingLocationListModule from '@/views/dashboard/trainingLocation/store/trainingLocationList'
import TrainingLocationFormModule from '@/views/dashboard/trainingLocation/store/trainingLocationForm'
import TrainingSessionListModule from '@/views/dashboard/trainingSession/store/trainingSessionList'
import LogisticSessionFormModule from '@/views/dashboard/trainingSession/store/logisticSessionForm'
import TrainerListModule from '@/views/dashboard/trainer/store/trainerList'
import TrainerFormModule from '@/views/dashboard/trainer/store/trainerForm'
import TrainerSpaceModule from '@/views/dashboard/trainers-space/store/trainerSpace'
import TrainerDocumentTypeModule from '@/components/molecules/dialog/store/trainerDocumentType'
import DocumentGenerateModule from '@/components/molecules/store/documentGenerate'
import VehicleListModule from '@/views/dashboard/equipment/store/vehicleList'
import VehicleFormModule from '@/views/dashboard/equipment/store/vehicleForm'
import TeachingToolListModule from '@/views/dashboard/equipment/store/teachingToolList'
import TeachingToolFormModule from '@/views/dashboard/equipment/store/teachingToolForm'
import EmailSessionDocumentModule from '@/components/molecules/dialog/store/emailSessionDocument'
import CalendarModule from '@/views/dashboard/planning/store/calendar'
import BookingModule from '@/views/dashboard/booking/store/booking'
import UserGroupListModule from '@/views/dashboard/userGroup/store/userGroupList'
import UserGroupFormModule from '@/views/dashboard/userGroup/store/userGroupForm'
import UserListModule from '@/views/dashboard/user/store/userList'
import UserFormModule from '@/views/dashboard/user/store/userForm'
import TrainingTypeFormModule from '@/views/dashboard/trainingType/store/trainingTypeForm'
import ProfessionFormModule from '@/views/dashboard/profession/store/professionForm'
import EventDayFormModule from '@/components/molecules/dialog/store/eventDayForm'
import ChangeStatusFormModule from '@/views/dashboard/trainee/store/changeStatusForm'
import LogisticAreaModule from '@/components/molecules/select-search/store/logisticArea'
import EventDayModule from '@/store/modules/eventDay'
import FiltersPlanningModule from '@/views/dashboard/planning/store/filtersPlanning'
import FiltersTrainingOfficerViewModule from '@/views/dashboard/trainingOfficers/store/filtersTrainingOfficerView'
import ConfigurationModule from '@/store/modules/configuration'
import ConfigurationFormModule from '@/views/dashboard/configuration/store/configurationForm'
import NotificationEmailModule from '@/store/modules/notificationEmail'
import NotificationEmailFormModule from '@/views/dashboard/notificationEmail/store/notificationEmailForm'
import trainingOfficerModule from '@/store/modules/trainingOfficer'

// Each store is the singleton instance of its module class
// Use these -- they have methods for state/getters/mutations/actions
// (result from getModule(...))
export let userStore: UserModule
export let areaStore: AreaModule
export let statusStore: StatusModule
export let trainingTypeStore: TrainingTypeModule
export let professionStore: ProfessionModule
export let themeStore: ThemeModule
export let navigationStore: NavigationModule
export let apiLogStore: ApiLogModule
export let carDealerSellerListStore: CarDealerSellerListModule
export let carDealerListStore: CarDealerListModule
export let carDealerFormStore: CarDealerFormModule
export let carTypeStore: CarTypeModule
export let carBrandStore: CarBrandModule
export let carModeleListStore: CarModeleListModule
export let carModelFormStore: CarModelFormModule
export let carModelListStore: CarModelListModule
export let carEngineStore: CarEngineModule
export let carFinishStore: CarFinishModule
export let carColorStore: CarColorModule
export let renterStore: RenterModule
export let companyContactListStore: CompanyContactListModule
export let traineeListStore: TraineeListModule
export let filterListStore: FilterListModule
export let traineeFormStore: TraineeFormModule
export let needTrainingFormStore: NeedTrainingFormModule
export let companyStore: CompanyModule
export let companyFormStore: CompanyFormModule
export let apiCompanyStore: ApiCompanyModule
export let importStore: ImportModule
export let reminderFormStore: ReminderFormModule
export let trainingLocationListStore: TrainingLocationListModule
export let trainingLocationFormStore: TrainingLocationFormModule
export let trainingSessionListStore: TrainingSessionListModule
export let logisticSessionFormStore: LogisticSessionFormModule
export let trainerListStore: TrainerListModule
export let trainerFormStore: TrainerFormModule
export let trainerSpaceStore: TrainerSpaceModule
export let TrainerDocumentTypeStore: TrainerDocumentTypeModule
export let DocumentGenerateStore: DocumentGenerateModule
export let vehicleListStore: VehicleListModule
export let vehicleFormStore: VehicleFormModule
export let teachingToolListStore: TeachingToolListModule
export let teachingToolFormStore: TeachingToolFormModule
export let emailSessionDocumentStore: EmailSessionDocumentModule
export let calendarStore: CalendarModule
export let filtersPlanningStore: FiltersPlanningModule
export let filtersTrainingOfficerViewStore: FiltersTrainingOfficerViewModule
export let bookingStore: BookingModule
export let userGroupListStore: UserGroupListModule
export let userGroupFormStore: UserGroupFormModule
export let userListStore: UserListModule
export let userFormStore: UserFormModule
export let trainingTypeFormStore: TrainingTypeFormModule
export let professionFormStore: ProfessionFormModule
export let eventDayFormStore: EventDayFormModule
export let ChangeStatusStore: ChangeStatusFormModule
export let logisticAreaStore: LogisticAreaModule
export let eventDayStore: EventDayModule
export let configurationStore: ConfigurationModule
export let configurationFormStore: ConfigurationFormModule
export let notificationEmailStore: NotificationEmailModule
export let notificationEmailFormStore: NotificationEmailFormModule
export let trainingOfficerStore: trainingOfficerModule

// initializer plugin: sets up state/getters/mutations/actions for each store
export function initializeStores (store: Store<any>): void {
  userStore = getModule(UserModule, store)
  areaStore = getModule(AreaModule, store)
  statusStore = getModule(StatusModule, store)
  trainingTypeStore = getModule(TrainingTypeModule, store)
  professionStore = getModule(ProfessionModule, store)
  areaStore = getModule(AreaModule, store)
  themeStore = getModule(ThemeModule, store)
  navigationStore = getModule(NavigationModule, store)
  apiLogStore = getModule(ApiLogModule, store)
  carDealerSellerListStore = getModule(CarDealerSellerListModule, store)
  carDealerListStore = getModule(CarDealerListModule, store)
  carDealerFormStore = getModule(CarDealerFormModule, store)
  carTypeStore = getModule(CarTypeModule, store)
  carBrandStore = getModule(CarBrandModule, store)
  carModeleListStore = getModule(CarModeleListModule, store)
  carModelFormStore = getModule(CarModelFormModule, store)
  carModelListStore = getModule(CarModelListModule, store)
  carEngineStore = getModule(CarEngineModule, store)
  carFinishStore = getModule(CarFinishModule, store)
  carColorStore = getModule(CarColorModule, store)
  renterStore = getModule(RenterModule, store)
  companyContactListStore = getModule(CompanyContactListModule, store)
  traineeListStore = getModule(TraineeListModule, store)
  filterListStore = getModule(FilterListModule, store)
  traineeFormStore = getModule(TraineeFormModule, store)
  needTrainingFormStore = getModule(NeedTrainingFormModule, store)
  companyStore = getModule(CompanyModule, store)
  companyFormStore = getModule(CompanyFormModule, store)
  apiCompanyStore = getModule(ApiCompanyModule, store)
  importStore = getModule(ImportModule, store)
  reminderFormStore = getModule(ReminderFormModule, store)
  trainingLocationListStore = getModule(TrainingLocationListModule, store)
  trainingLocationFormStore = getModule(TrainingLocationFormModule, store)
  trainingSessionListStore = getModule(TrainingSessionListModule, store)
  logisticSessionFormStore = getModule(LogisticSessionFormModule, store)
  trainerListStore = getModule(TrainerListModule, store)
  trainerFormStore = getModule(TrainerFormModule, store)
  trainerSpaceStore = getModule(TrainerSpaceModule, store)
  TrainerDocumentTypeStore = getModule(TrainerDocumentTypeModule, store)
  DocumentGenerateStore = getModule(DocumentGenerateModule, store)
  vehicleListStore = getModule(VehicleListModule, store)
  vehicleFormStore = getModule(VehicleFormModule, store)
  teachingToolListStore = getModule(TeachingToolListModule, store)
  teachingToolFormStore = getModule(TeachingToolFormModule, store)
  emailSessionDocumentStore = getModule(EmailSessionDocumentModule, store)
  calendarStore = getModule(CalendarModule, store)
  filtersPlanningStore = getModule(FiltersPlanningModule, store)
  filtersTrainingOfficerViewStore = getModule(FiltersTrainingOfficerViewModule, store)
  bookingStore = getModule(BookingModule, store)
  userGroupListStore = getModule(UserGroupListModule, store)
  userGroupFormStore = getModule(UserGroupFormModule, store)
  userListStore = getModule(UserListModule, store)
  userFormStore = getModule(UserFormModule, store)
  trainingTypeFormStore = getModule(TrainingTypeFormModule, store)
  professionFormStore = getModule(ProfessionFormModule, store)
  eventDayFormStore = getModule(EventDayFormModule, store)
  ChangeStatusStore = getModule(ChangeStatusFormModule, store)
  logisticAreaStore = getModule(LogisticAreaModule, store)
  eventDayStore = getModule(EventDayModule, store)
  configurationStore = getModule(ConfigurationModule, store)
  configurationFormStore = getModule(ConfigurationFormModule, store)
  notificationEmailStore = getModule(NotificationEmailModule, store)
  notificationEmailFormStore = getModule(NotificationEmailFormModule, store)
  trainingOfficerStore = getModule(trainingOfficerModule, store)
}

// for use in 'modules' store init (see store/index.ts), so each module
// appears as an element of the root store's state.
// (This is required!)
export const modules = {
  user: UserModule,
  area: AreaModule,
  status: StatusModule,
  trainingType: TrainingTypeModule,
  profession: ProfessionModule,
  theme: ThemeModule,
  navigation: NavigationModule,
  apiLog: ApiLogModule,
  carDealerSellerList: CarDealerSellerListModule,
  carDealerList: CarDealerListModule,
  carDealerForm: CarDealerFormModule,
  carType: CarTypeModule,
  carBrand: CarBrandModule,
  carModeleList: CarModeleListModule,
  carModelForm: CarModelFormModule,
  carModelList: CarModelListModule,
  carEngine: CarEngineModule,
  carFinish: CarFinishModule,
  carColor: CarColorModule,
  renter: RenterModule,
  companyContactList: CompanyContactListModule,
  traineeList: TraineeListModule,
  filterList: FilterListModule,
  traineeForm: TraineeFormModule,
  needTrainingForm: NeedTrainingFormModule,
  company: CompanyModule,
  companyForm: CompanyFormModule,
  apiCompany: ApiCompanyModule,
  import: ImportModule,
  reminderForm: ReminderFormModule,
  trainingLocationList: TrainingLocationListModule,
  trainingLocationForm: TrainingLocationFormModule,
  trainingSessionList: TrainingSessionListModule,
  logisticSessionForm: LogisticSessionFormModule,
  trainerList: TrainerListModule,
  trainerForm: TrainerFormModule,
  trainerSpace: TrainerSpaceModule,
  TrainerDocumentType: TrainerDocumentTypeModule,
  DocumentGenerate: DocumentGenerateModule,
  vehicleList: VehicleListModule,
  vehicleForm: VehicleFormModule,
  teachingToolList: TeachingToolListModule,
  teachingToolForm: TeachingToolFormModule,
  emailSessionDocument: EmailSessionDocumentModule,
  calendar: CalendarModule,
  filtersPlanning: FiltersPlanningModule,
  filtersTrainingOfficerView: FiltersTrainingOfficerViewModule,
  userGroupList: UserGroupListModule,
  userGroupForm: UserGroupFormModule,
  userList: UserListModule,
  userForm: UserFormModule,
  booking: BookingModule,
  trainingTypeForm: TrainingTypeFormModule,
  professionForm: ProfessionFormModule,
  eventDayForm: EventDayFormModule,
  changeStatusForm: ChangeStatusFormModule,
  logisticArea: LogisticAreaModule,
  eventDay: EventDayModule,
  configuration: ConfigurationModule,
  configurationForm: ConfigurationFormModule,
  notificationEmail: NotificationEmailModule,
  notificationEmailForm: NotificationEmailFormModule,
  trainingOfficer: trainingOfficerModule,
}
